import React, { useContext, useState } from "react";
import { StoreContext } from "../store";
import { SendMessage } from "../actions";

const Notificationoptin = () => {
  const { dispatch } = useContext(StoreContext);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [carrier, setCarrier] = useState('');
  const [notifyByEmail, setNotifyByEmail] = useState(false);
  const [notifyBySMS, setNotifyBySMS] = useState(false);
  const [name, setName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});

  const handleSave = () => {
    setFieldErrors({});
    setErrorMessage('');
    setSuccessMessage('');

    const errors = {};

    if (!name.trim()) {
      errors.name = true;
      setErrorMessage("Name is required.");
    } else if (!notifyByEmail && !notifyBySMS) {
      setErrorMessage("Please select at least one notification method.");
    } else if (notifyByEmail && !email.trim()) {
      errors.email = true;
      setErrorMessage("Email is required when opting in for email notifications.");
    } else if (notifyBySMS) {
      // ✅ US phone number validation: Must be numeric, 11 digits, start with 1
      const digitsOnly = phone.replace(/\D/g, '');
      const isValidUSNumber = digitsOnly.length === 11 && digitsOnly.startsWith('1');
  
      if (!isValidUSNumber) {
        errors.phone = true;
        setErrorMessage("Please enter a valid US phone number starting with '1' and containing 11 digits.");
      }
    }
  

    if (Object.keys(errors).length > 0 || (!notifyByEmail && !notifyBySMS)) {
      setFieldErrors(errors);
      return;
    }

    if (notifyByEmail) {
      const Message = {
        Name: name,
        Email: email,
        Phone: "",
        MessageBody: "subscribe to notification",
        Recipient: "",
      };
      SendMessage(dispatch, Message);
    }

    if (notifyBySMS) {
      const Message = {
        Name: name,
        Email: phone + carrier,
        Phone: "",
        MessageBody: "subscribe to notification",
        Recipient: "",
      };
      SendMessage(dispatch, Message);
    }

    setSuccessMessage("🎉 Preferences saved successfully!");
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#e5e7eb' }}>
      <div style={{ width: '100%', maxWidth: '600px', padding: '2rem', backgroundColor: 'white', borderRadius: '1rem', textAlign: 'left', boxShadow: '0 0 20px rgba(0,0,0,0.1)', border: '1px solid #e5e7eb' }}>
        <h1 style={{ fontSize: '1.875rem', fontWeight: '800', color: '#1e3a8a', textAlign: 'center' }}>Notification Preferences</h1>
        <p style={{ fontSize: '0.875rem', color: '#6b7280', marginBottom: '1.5rem', textAlign: 'center' }}>Choose how you'd like to receive notifications.</p>

        {errorMessage && (
          <div style={{ backgroundColor: '#fee2e2', color: '#991b1b', padding: '1rem', borderRadius: '8px', marginBottom: '1rem' }}>
            {errorMessage}
          </div>
        )}

        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <div style={{ width: '100%', backgroundColor: '#dbeafe', padding: '1rem', borderRadius: '8px' }}>
            <label style={{ display: 'block', marginBottom: '0.5rem', fontWeight: '500', color: '#374151' }}>Name</label>
            <input
              type="text"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ width: '100%', padding: '0.5rem', border: fieldErrors.name ? '2px solid #dc2626' : '1px solid #d1d5db', borderRadius: '4px' }}
            />
          </div>

          <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <input
              type="checkbox"
              id="email-opt-in"
              checked={notifyByEmail}
              onChange={(e) => setNotifyByEmail(e.target.checked)}
            />
            <label htmlFor="email-opt-in">Notify me via Email</label>
          </div>

          {notifyByEmail && (
            <div style={{ width: '100%', backgroundColor: '#dbeafe', padding: '1rem', borderRadius: '8px' }}>
              <label style={{ display: 'block', marginBottom: '0.5rem', fontWeight: '500', color: '#374151' }}>Email Address</label>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ width: '100%', padding: '0.5rem', border: fieldErrors.email ? '2px solid #dc2626' : '1px solid #d1d5db', borderRadius: '4px' }}
              />
            </div>
          )}

          <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <input
              type="checkbox"
              id="sms-opt-in"
              checked={notifyBySMS}
              onChange={(e) => setNotifyBySMS(e.target.checked)}
            />
            <label htmlFor="sms-opt-in">Notify me via SMS(text)</label>
          </div>

          {notifyBySMS && (
            <>
              <div style={{ width: '100%', backgroundColor: '#dbeafe', padding: '1rem', borderRadius: '8px' }}>
                <label style={{ display: 'block', marginBottom: '0.5rem', fontWeight: '500', color: '#374151' }}>Phone Number</label>
                <input
                  type="tel"
                  placeholder="Enter your phone number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={{ width: '100%', padding: '0.5rem', border: fieldErrors.phone ? '2px solid #dc2626' : '1px solid #d1d5db', borderRadius: '4px' }}
                />
              </div>
{/* 
              <div style={{ width: '100%', backgroundColor: '#dbeafe', padding: '1rem', borderRadius: '8px' }}>
                <label style={{ display: 'block', marginBottom: '0.5rem', fontWeight: '500', color: '#374151' }}>Mobile Carrier</label>
                <select
                  value={carrier}
                  onChange={(e) => setCarrier(e.target.value)}
                  style={{ width: '100%', padding: '0.5rem', border: fieldErrors.carrier ? '2px solid #dc2626' : '1px solid #d1d5db', borderRadius: '4px' }}
                >
                  <option value="">Select Your Mobile Carrier</option>
                  <option value="vtext.com">Verizon</option>
                  <option value="txt.att.net">AT&T</option>
                  <option value="tmomail.net">T-Mobile</option>
                  <option value="messaging.sprintpcs.com">Sprint</option>
                  <option value="email.uscc.net">US Cellular</option>
                  <option value="vmobl.com">Virgin Mobile</option>
                  <option value="sms.mycricket.com">Cricket</option>
                </select>
              </div> */}
            </>
          )}

          <button
            onClick={handleSave}
            style={{
              width: '100%',
              background: 'rgb(0, 51, 102)',
              color: 'white',
              fontWeight: '600',
              padding: '0.75rem',
              borderRadius: '6px',
              border: 'none',
              cursor: 'pointer'
            }}
          >
            Submit
          </button>

          {successMessage && (
            <div style={{ backgroundColor: '#d1fae5', color: '#065f46', padding: '1rem', borderRadius: '8px', marginTop: '1rem' }}>
              {successMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notificationoptin;
